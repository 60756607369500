.dropdown-button {
  color: #351051;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-right: 48px;
  font-Family: "Helvetica";
}

.dropdown-button::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-button:hover {
  opacity: 0.5;
}

.dropdown-list {
  position: absolute;
  border-radius: 4;
  top: 110%;
  right: 0;
  z-index: 100;
  padding: 12px 0px;
  background-color: #FFFFFF;
  border: 1px solid lightgray;
  margin-right: 48px;
  font-Family: "Helvetica";
}

.dropdown-item {
  font-size: 16px;
  padding: 2px 24px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}