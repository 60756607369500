.DayPicker_focusRegion{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.CalendarDay__selected_span {
  background: #351051;
  color: white; 
  border: 1px solid #351051
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover{
  background: #9c27b0;
  border: 1px double #9c27b0;
}
.CalendarDay__selected {
  background: #351051;
  color: white;
  border: 1px double #9c27b0;
}
.CalendarDay__selected:hover {
  background: #9c27b0;
  color: white;
}
.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span {
  background: #9c27b0;
  color: white;
  border: 1px solid #351051
}
.DateInput_input {
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 12px;
  font-weight: 500;
  border: none;
}

.DateRangePicker {
  border-radius: 12px;
  border: 1px solid #dfe2e3;
  min-width: 240px;
  height: 56px;
}

.DateRangePickerInput {
  min-height: 56px;
  display: flex;
  align-items: center;
}

.DateRangePickerInput, .DateInput {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  border-radius: 12px;
}

.DateInput_input__focused {
  border: none;
}

.DayPickerKeyboardShortcuts_buttonReset{
  display: none;
}
.CalendarMonth_caption::first-letter {
  text-transform: capitalize;
}
.DateRangePickerInput_arrow {
  z-index: 1;
}